:root {
    --text-primary-color: #252a34;
    --bg-white-color: #fff;
    --transition-speed: 0.3s;
    --bg-color-light: #f3f9f1;
    --text-color-dark: #fff;
    --bg-color-article-dark: #3d3b4f;
    --bg-color-article-light: #e0f0e9;
    --quote-border-color: #14b8a6;
}
@font-face {
    font-family: "qiantumark";
    src: url(./fonts/千图马克手写体.ttf) format("truetype");
}
body {
    margin: 0;
    font-optical-sizing: auto;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--bg-color-light);
    color: var(--text-primary-color);
    font-size: 1.2rem;
    color: var(--text-color-light); /* Default text color */
    transition: background-color var(--transition-speed),
        color var(--transition-speed); /* Smooth transition */
}

.dark-mode {
    background-color: var(
        --text-primary-color
    ) !important; /* Dark mode background color */
    color: var(--text-color-dark) !important;
}
.dark-mode .Article {
    background-color: var(--bg-color-article-dark);
}

.inline-code {
    color: #14b8a6 !important;
    white-space: nowrap;
}

.inline-highlight,
strong {
    color: #f43f5e;
    font-weight: 600;
}

blockquote {
    color: rgba(0, 0, 0, 0.5);
}

.dark-mode blockquote {
    color: rgba(255, 255, 255, 0.5);
}

.react-flow__controls-button {
    color: #252a34 !important;
}
